import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyLink from 'gatsby-link';
import styled from '@emotion/styled';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Menu from './Menu';
import MenuItem from './Menu/MenuItem';
import { nest } from '../helpers/ContentHelpers'
import { StateConsumer } from '../context/StateContext';
import userAv from '../assets/global-images/user/userAv.jpg';

const NavbarBase = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
`;

const Nav = styled(NavbarBase)`
  /* box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2); */
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition: all 200ms ${(props) => (props.show ? 'ease-in' : 'ease-out')};
  transform: ${(props) => (props.show ? 'none' : 'translate(0, -100%)')};
`;

const MenuItemExternal = (props) => <li><a href={props.to}>{props.children}</a></li>;

const Navbar = (props) => {

  const [hideNavbarOnScroll, setHideNavbarOnScroll] = useState(true);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      // Note: prevPos.y > -12 is here to fix Nav component disappearing bug
      // due to elastic scrolling/bounce effect in mobile Safari.
      const isShow = currPos.y > prevPos.y || prevPos.y > -12;
      if (isShow !== hideNavbarOnScroll) setHideNavbarOnScroll(isShow);
    },
    [hideNavbarOnScroll],
    null,
    false,
    100
  );

  const data = useStaticQuery(graphql`
    {
      formlightCommon {
        logo
      }
      formlightMenu(name: {eq: "formlight-mainmenu"}) {
        id
        menu_items {
          title
          alias
          children
        }
      }
    }
  `);

  // const {
  //   storeName,
  //   menu
  // } = data.site.siteMetadata.gatsbyStorefrontConfig;


  // const menuItems = nest(menu, parseInt(0), 'parentId')

  const newMenu = data.formlightMenu.menu_items

  // console.log(newMenu)

  const {
    logo
  } = data.formlightCommon;

  return (
    <StateConsumer>
      {({ myBock, myOrders, cart }) => {
        const { isAuthenticated, name } = myBock;
        let cartCount = null;
        if(cart.items && cart.items.length > 0){
          cartCount = cart.items.length
        }

        return(
      <Nav show={hideNavbarOnScroll}>
        <header class="header">
          <div class="header-deafult menu-right d-flex align-items-center">
            <div class="container-fluid pl-80 pr-80 pl-lg-15 pl-md-15 pl-sm-15 pl-xs-15 pr-lg-15 pr-md-15 pr-sm-15 pr-xs-15">
              <div class="row align-items-center">
                <div class="col-xl-3 col-lg-6 col-md-6 col-4">
                  <GatsbyLink to="/">
                    <div class="logo ccnLogoI" style={{backgroundImage: `url(${logo})`}}>
                      FormLight by Bock
                    </div>
                  </GatsbyLink>
                </div>
                <div class="col-xl-6  d-none d-xl-block">
                  <div class="menu-center d-flex justify-content-center">
                    <nav class="main-menu ">
                      <ul>
                        {newMenu.length > 0 && newMenu.map((item, k) => {
                          return (
                          <MenuItem
                            to={item.alias}
                            subItems={item.children}
                          >
                            {item.title}
                          </MenuItem>
                          )
                        })}
                      </ul>
                    </nav>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-8 d-flex justify-content-end">
                  {/*<Search width="25px" height="25px" color="primary" />*/}
                      <div class="header-icon cart ">
                        <GatsbyLink className="cart-trigger" to="/cart">
                          {(cartCount !== null && cartCount > 0) && <span class="cart-count">{cartCount}</span>}
                          <i class="dlicon shopping_cart-modern"></i>
                        </GatsbyLink>
                      </div>
                      <nav class="ccnDrops" role="navigation">
                        <ul>
                          {isAuthenticated === false &&
                            <li><GatsbyLink to={'/account'}>Login</GatsbyLink></li>
                          }
                          {isAuthenticated === true &&
                            <li>
                              <a href="#">
                                <span>{name}</span>
                                <img
                                  src={userAv}
                                  alt="Profile"
                                  className="nav-user-profile rounded-circle"
                                  width="50"
                                />
                              </a>
                              <ul class="dropdown">
                                <li><GatsbyLink to="/account">Account & Orders</GatsbyLink></li>
                                <li><a href="https://api.source.xyz/user/logout/sso">Logout</a></li>
                              </ul>
                            </li>
                          }
                        </ul>
                      </nav>
                    <Menu menu={newMenu} />
                  </div>
                </div>
              </div>
            </div>
          </header>
        </Nav>
      )}}
    </StateConsumer>
  )
}

export default Navbar

import React from 'react';
import GatsbyLink from 'gatsby-link';
import { excludeNodeEnv } from '../../helpers/DomHelper'

export default function MenuItem(props){

  const {
    subItems,
    to,
    children
  } = props

  let hasSubMenu = false;
  if(subItems) {
    hasSubMenu = subItems.length > 0 ? true : false;
  }

  let menuItemClass = '';

  if(hasSubMenu === true){
    menuItemClass = 'ccnSub_top'
  }

  const domDocDefined = excludeNodeEnv()

  let activeItem = false
  if(domDocDefined && (to === window.location.pathname)) activeItem = true

  return (

    <li className={menuItemClass}>
      {activeItem === false &&
        <GatsbyLink to={to}>
          {children}
        </GatsbyLink>
      }
      {activeItem === true &&
        <GatsbyLink>
          {children}
        </GatsbyLink>
      }
      {(hasSubMenu) && (
        <ul className="sub-menu">
          {subItems.map((item, key) => {
            return (
              <li>
                <GatsbyLink to={item.alias}>
                  {item.name}
                </GatsbyLink>
              </li>
            )
          })}
        </ul>
      )}
    </li>
  )
}

import React, { useState } from 'react';
import { Box } from 'rebass';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyLink from 'gatsby-link';
import styled from '@emotion/styled';
import { nest } from '../../helpers/ContentHelpers'
import MenuItem from './MenuItem';
import { useMenuContext } from './context';
import { StateConsumer } from '../../context/StateContext';

const DisabledArea = styled(Box)`
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  overflow: 'hidden';
`;


const Menu = ({ menu: componentMenu }) => {

  const [parentId, setParentId] = useState(0);
  const [showSidebar, setShowsidebar] = useState(false);
  const { menuShowed, setMenuShowed } = useMenuContext();


  const menuItems = nest(componentMenu, parseInt(0), 'parentId')

  const updateParentId = id => {
    setParentId(id);
  };

  const toggleSidebar = () => {
    setShowsidebar(!showSidebar);
    setMenuShowed(!menuShowed);
  };

  let hamburgerClass = '';
  if (showSidebar) {
    hamburgerClass = 'is-visible';
  }


  const subMenuCloseAll = () => {
    let subCollections = document.querySelectorAll('.sub-menu-two')
    let subSubCollections = document.querySelectorAll('.lavel--3')
    subCollections.forEach((collection) => {
      collection.style.display='none'
    });
    subSubCollections.forEach((collection) => {
      collection.style.display='none'
    });
  }


  const subMenu = v => {

    subMenuCloseAll();
    let subCollection = v.target.nextSibling;
    subCollection.style.display='block';
  };

  const subSubMenu = v => {
    let subCollection = v.target.nextSibling
    subCollection.style.display='block'

  };

  const data = useStaticQuery(graphql`
    {
      formlightCommon {
        logo
      }
      formlightMenu(name: {eq: "formlight-mainmenu"}) {
        id
        menu_items {
          title
          alias
          children
        }
      }
    }
  `);

  const newMenu = data.formlightMenu.menu_items


  return (
    <>

    <StateConsumer>

    {({ myBock, myOrders, cart }) => {

      const { isAuthenticated, name } = myBock;

  return (
    <>


      <div class="header-icon hamburger-menu " onClick={toggleSidebar}>
          <a class="hamburger-trigger d-none d-xl-block" href="#">
              <i class="dlicon ui-3_menu"></i>
          </a>

          <a class="hamburger-trigger d-block d-xl-none" href="#">
              <i class="dlicon ui-3_menu"></i>
          </a>
      </div>


      <div className={`hamburger-area ${hamburgerClass}`}>
        <div class="btn-close-search" onClick={toggleSidebar}>
          <button>
            <i className="dlicon ui-1_simple-remove"></i>
          </button>
        </div>
        <div class="hamburger-menu-main d-none d-xl-block">

      </div>


      <ul class="menu-primary-menu-1 responsive-manu d-block d-xl-none" id="responsive-manu">
        <MenuItem
          to="/">
          Home
        </MenuItem>
        {componentMenu
          ? componentMenu.map((item, k) => {
              return (
                <MenuItem
                  key={k}
                  to={item.alias}
                  subItems={item.children}
                >
                  {item.title}
                </MenuItem>
              )
            })
          : ''}
                <li className="menuSep"></li>
                {isAuthenticated === false &&
                  <li><GatsbyLink to={'/account'}>Login</GatsbyLink></li>
                }
                {isAuthenticated === true &&
                  <>
                    <li><GatsbyLink to="/account">Account & Orders</GatsbyLink></li>
                    <li><a href="https://api.source.xyz/user/logout/sso">Logout</a></li>
                  </>
                }
              </ul>
            </div>
            {showSidebar &&
              <DisabledArea onClick={toggleSidebar} />
            }
          </>
        )}}
      </StateConsumer>
    </>
  )
}

export default Menu;

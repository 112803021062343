import React from 'react';
// import { ThemeProvider, Styled } from 'theme-ui';
// import { Styled } from 'theme-ui';
import Helmet from 'react-helmet';
import { Flex, Box } from 'rebass';
// import ReactGA from 'react-ga';
import { useStaticQuery, graphql } from 'gatsby';
import { ParallaxProvider } from 'react-scroll-parallax';
import { excludeNodeEnv } from '../../helpers/DomHelper'
import NewsletterSubscribe from '../Blocks/NewsletterSubscribe';

import {
  MenuContextProvider,
  useMenuContext,
} from '../Menu/context';

// import {
//   SearchContextProvider,
//   useSearchContext,
// } from '../components/Search/context';
import Navbar from '../Navbar';
import Footer from '../Commons/Footer/Footer';
// import theme from '../gatsby-plugin-theme-ui/index';

// import './reset.css';

// const initializeReactGA = googleAnalyticsId => {
//   ReactGA.initialize(googleAnalyticsId);
//   if (typeof window !== `undefined`) {
//     ReactGA.pageview(window.location.pathname + window.location.search);
//   }
// };

const pageTitleTemplate = "%s"

const Layout = ({ type, children, metaTitle, metaDesc }) => {
  // const data = useStaticQuery(
  //   graphql`
  //     query layoutStaticQueryAndLayoutStaticQuery {
  //       site {
  //         siteMetadata {
  //           gatsbyStorefrontConfig {
  //             googleAnalyticsId
  //           }
  //         }
  //       }
  //     }
  //   `
  // );

  // const { googleAnalyticsId } = data.site.siteMetadata.gatsbyStorefrontConfig;

  let pageTitle = 'FormLight by Bock'
  let pageDescription = 'FormLight by Bock Lighting'

  if(
    typeof metaTitle !== 'undefined'
    && metaTitle !== null
  ){
    pageTitle = metaTitle + ' - FormLight by Bock'
  }
  if(
    typeof metaDesc !== 'undefined'
    && metaDesc !== null
  ){
    pageDescription = metaDesc
  }

  // initializeReactGA(googleAnalyticsId);

  return (
<>
    {/*<StateProvider>*/}
    {/*<ThemeProvider theme={theme}>*/}
      {/*<Styled.root>*/}
        <MenuContextProvider>
          {/*<SearchContextProvider>*/}
            <ParallaxProvider>
              <Helmet title={pageTitle} titleTemplate={pageTitleTemplate} defer={false}>
                <meta name="description" content={pageDescription} />
              </Helmet>
              <LayoutComponents type={type} children={children} />
            </ParallaxProvider>
          {/*</SearchContextProvider>*/}
        </MenuContextProvider>
      {/*</Styled.root>*/}
    {/*</ThemeProvider>*/}
    {/*</StateProvider>*/}
    </>

  );
};

const LayoutComponents = ({ type, children }) => {
  const { menuShowed } = useMenuContext();
  // const { searchShowed } = useSearchContext();

  let classes = {
    maxWidth: 'none',
    height: '100%',
    position: 'relative'
   };

  let topClasses = {
    minHeight: '100vh',
  }

  if(type === 'contentPage'){
    classes = {
      maxWidth: 'none',
      height: '100%',
      marginBottom: '150px'
    }
  }
  if(type === 'fullHeight'){
    classes = {
      maxWidth: 'none',
      height: 'calc(100vh - 95px)',
    }
    topClasses = {
      // overflow: 'hidden',
      minHeight: 'calc(100vh - 94px)'
    }

    if(excludeNodeEnv() === true){
      let styles = `html, body, .site-wrapper { height: auto }`
      let styleSheet = document.createElement("style")
      styleSheet.type = "text/css"
      styleSheet.innerText = styles
      document.head.appendChild(styleSheet)
    }

  }
  return (
    <Box
      bg="background"
      sx={{
        position: menuShowed ? 'fixed' : 'relative',
        overflow: menuShowed ? 'hidden' : 'visible',
        width: '100%'
      }}
      className="ccnWrapper"
    >
      <Helmet>
        <html lang="en" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
      </Helmet>

      <Flex flexDirection="column" style={topClasses}>
        <Navbar />
        <Box
          as="main"
          flex="1"
          width={1}
          style={classes}
          mx="auto"
          mt={['0', '0']}
        >
          {children}

        </Box>
        {type !== 'fullHeight' &&
          <>
            <NewsletterSubscribe />
            <Footer />
          </>
        }
      </Flex>
    </Box>
  );
};

export default Layout;

import React from 'react';
import GatsbyLink from 'gatsby-link';
import { useStaticQuery, graphql } from 'gatsby';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

function Footer(props) {

  const data = useStaticQuery(graphql`
    query formlightFooter {
      formlightCommon {
        logo
        copyright
        email
        address
        desc
        phone
        footer_menu_3
        footer_menu_2
        footer_menu_1
        socials {
          facebook
          twitter
          instagram
          youtube
          pinterest
          houzz
        }
      }
    }`);

  const {
    logo,
    copyright,
    email,
    address,
    desc,
    phone,
    socials,
    footer_menu_3,
    footer_menu_2,
    footer_menu_1
  } = data.formlightCommon;

  const {
    facebook,
    twitter,
    instagram,
    youtube,
    pinterest,
    houzz
  } = socials

  const mortarr = null;

  return (
    <footer class="footer-default section bg-white pl-100 pr-100 pl-lg-15 pl-md-15 pl-sm-15 pl-xs-15 pr-lg-15 pr-md-15 pr-sm-15 pr-xs-15">
      <div class="footer-top-area pt-95 pt-lg-75 pt-md-65 pt-sm-60 pt-xs-45">
        <div class="row">
          <div class="footer-widget widget_style--2 footer-column footer-column__a  col-xl-4 col-lg-12 col-md-12 col-sm-6 col-12 text-center mb-40 mb-sm-30 mb-xs-20">
            <div class="inner">
              <div class="logo">
                <GatsbyLink to="/" style={{backgroundImage: `url(${logo})`}}>
                  FormLight by Bock
                </GatsbyLink>
              </div>
              <div class="footer-content">
                <p class="pra">{ReactHtmlParser(desc)}</p>
                <div class="footer-social">
                  {facebook && <a class="facebook" target="_blank" href={facebook}><i class="fa fa-facebook"></i></a>}
                  {twitter && <a class="twitter" target="_blank" href={twitter}><i class="fa fa-twitter"></i></a>}
                  {instagram && <a class="instagram" target="_blank" href={instagram}><i class="fa fa-instagram"></i></a>}
                  {mortarr && <a class="mortarr" target="_blank" href={mortarr}><i class="fa ccn-fa-mortarr"></i></a>}
                  {houzz && <a class="houzz" target="_blank" href={houzz}><i class="fa ccn-fa-houzz"></i></a>}
                  {pinterest && <a class="pinterest" target="_blank" href={pinterest}><i class="fa fa-pinterest"></i></a>}
                  {youtube && <a class="youtube" target="_blank" href={youtube}><i class="fa fa-youtube"></i></a>}
                </div>
              </div>
            </div>
          </div>
          {footer_menu_1 &&
            <div class="footer-widget widget_style--2 footer-column footer-column__b  col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12 mb-40 mb-sm-30 mb-xs-20">
              <div class="inner">
                <div class="footer-content">
                  {ReactHtmlParser(footer_menu_1)}
                  {/*<ul class="quick-link">
                          <li><GatsbyLink to="/about"><span>About Us</span></GatsbyLink></li>
                          <li><GatsbyLink to="/policy/termsOfService"><span>Terms of Service</span></GatsbyLink></li>
                          <li><GatsbyLink to="/policy/privacyPolicy"><span>Privacy Policy</span></GatsbyLink></li>
                          <li><GatsbyLink to="/policy/refundPolicy"><span>Refund Policy</span></GatsbyLink></li>
                  </ul>*/}
                </div>
              </div>
            </div>
          }
          {footer_menu_2 &&
            <div class="footer-widget widget_style--2 footer-column footer-column__b  col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12 mb-40 mb-sm-30 mb-xs-20">
              <div class="inner">
                <div class="footer-content">
                  {ReactHtmlParser(footer_menu_2)}
                </div>
              </div>
            </div>
          }
          {footer_menu_3 &&
            <div class="footer-widget widget_style--2 footer-column footer-column__b  col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12 mb-40 mb-sm-30 mb-xs-20">
              <div class="inner">
                <div class="footer-content">
                  {ReactHtmlParser(footer_menu_3)}
                </div>
              </div>
            </div>
          }
          <div class="footer-widget widget_style--2 footer-column footer-column__c col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12 mb-40 mb-sm-30 mb-xs-20">
            <div class="inner">
              <div class="footer-content">
                <div class="address-two">
                  {email && <p><strong>E:  </strong> <span class="list-content"><a href={`mailto:${email}`}>{email}</a></span></p>}
                  {phone && <p><strong>P:  </strong> <span class="list-content">{phone}</span></p>}
                  {address && <p><strong>A:  </strong> <span class="list-content">{address}</span></p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {copyright &&
        <div class="copyright-area pt-30 pt-lg-10 pt-md-0 pt-sm-0 pt-xs-15 pb-50">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <div class="content text-center">
                <p>{copyright}</p>
              </div>
            </div>
          </div>
        </div>
      }
    </footer>
  );
}

export default Footer;
